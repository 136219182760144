
.enquiry-block {
    background-color: #FF6737;
    border-radius: 14px;
    .enquiry-info {
        max-width: 330px;
        width: 100%;
        @media only screen and (max-width: 1023px) {
            margin-left: auto;
            margin-right: auto;
        }
        
    }
    .enquiry-picture {
        .cta-image {
            height: 368px;
            object-fit: cover;
            @media only screen and (max-width: 1023px) {
                height: initial;
                max-width: 360px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}