/* components/VideoModal.module.css */
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent; /* Set the background color of the modal */
    padding: 20px;
    outline: none;
    z-index: 1000;
    border-radius: 5px;
  }

  .modal iframe{
    width: 900px;
    height: 600px;

    @media only screen and (max-width: 1000px) {
      width: 700px;
      height: 500px;
    }

    @media only screen and (max-width: 650px) {
      width: 400px;
      height: 400px;
    }
  }  

  .closeButton {
    margin-bottom: 10px;
    text-align: right;
  }
  
  .closeButton>button{
    color: #FF4514;
    background-color: transparent;
    border-color:none;
    border: none;
    border-radius: 5px;
    padding: 0;
    
  }

  .closeButton>button:hover{
    color: white;
    background-color: transparent;
    border-color:none;
    border: none;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9); /* Set the background color and opacity of the overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  