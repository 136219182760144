button {
    flex-shrink: 0;
    border-radius: 14px;
    border-width: 1px;
    border-style: solid;
    padding: 12px 1.2rem;
    span {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.4;
        color: white;
        @media only screen and (max-width: 639px) {
            font-size: 14px;
            font-weight: 500;
        }
    }
    &.button-orange {
        background-color: #FF4514;
        border-color: #FF4514;
    }
    &.button-grey {
        background-color: #3D4152;
        border-color: #3D4152;
        &:hover {
            background-color: #FF7B59;
            border-color: #FF7B59;
        }
        &:focus,
        &:active:hover,
        &:focus:hover,
        &:active {
            background-color: #C73C17;
            border-color: #C73C17;
        }
    }
    &.button-white {
        background-color: #fff;
        border-color: #fff;
        display: inline;
        span {
            color: #25D366;
        }
        &:hover {
            background-color: #25D366;
            border-color: #25D366;
            span {
                color: #fff;
            }
        }
    }
    &.button-outline {
        background-color: transparent;
        border-color: #fff;
    }
    &.button-green {
        background-color: #25D366;
        border-color: #25D366;
        &:hover {
            background-color: #1BA44E;
            border-color: #1BA44E;
        }
    }
    &:focus,
    &:focus:hover,
    &:active,
    &:active:hover,
    &:hover {
        background-color: #25D366;
        border-color: #25D366;
        span {
            color: #fff;
        }
    }

    @media (min-width:1340px) and (max-width:1370px){
        padding: 12px 0.8rem;
    }
}