footer {
    background-color: #1D1B1B;
    color: #F8ECE8;
    margin-top: -48px;

    h6 {
        color: #888;
    }

    .copy-rights-block {
        border-top: 1px solid rgba(255, 255, 255, .33);
    }

    .social-list {
        .social-list-item {
            a {
                img {
                    opacity: 0.8;
                }

                &:hover {
                    img {
                        transform: scale(1.1);
                        opacity: 1;
                    }
                }
            }
        }
    }

    .contact-details {
        * {
            max-width: 360px;
        }

        address {
            font-style: normal;
        }
    }

    .list {
        a {
            color: #F8ECE8;

            &:hover {
                color: #FF7B59;
                // font-weight: 600;
            }
        }
    }

    .destination-block {
        .destination-inner {
            border-bottom: 1px solid #3D3D3D;
        }

        .destination-list {
            li {
                &:not(:first-child) {
                    &:before {
                        content: '';
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background-color: #888;
                        display: block;
                    }
                }

                @media only screen and (max-width: 1200px) {
                    &:first-child {
                        &:before {
                            content: '';
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            background-color: #888;
                            display: block;
                        }
                    }

                }


            }

            &.package-laist {
                @media only screen and (max-width: 565px) {
                    li {
                        width: 100%;
                    }
                }
            }
        }
    }
    button.button.button-green.fixed {
        z-index: 1000000;
        bottom: calc(50% - 25px);
        right: 5px;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        padding: 0px;

        &.mobile{
            display: none;
        }
        @media (max-width:767px){
            &.mobile{
                display: block;
            }
        }

        img{
            width: 40px;
        }
    }
}